import Form from '@/components/form/Form.vue'
import api from '@/api/team'

export default {
	metaInfo: {
		title: 'Team – ImmerseFX'
	},
  components: {Form},
  created() {
    api.getAll(this)
  },
  computed: {
    listData() {
      if (this.state.team?.data) {
        return this.state.team.data
      }
      return []
    }
  }
}
